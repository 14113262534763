body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    color: #3eacef;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

img {
    max-width: 100%;
    max-height: 100%;
}

/* list and edit */
.editForm{
    padding: 20px 2px;
    font-size: 14px;
    line-height: 1.5;
    min-width: 1000px;
    width: 100%;
    /* height: calc(100vh - 3em); */
    overflow: auto;
    box-sizing: border-box;
}

.editForm .main {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.editForm .topBtn {
    margin-bottom: 10px;
}

.editForm .form {
    padding: 20px;
}

.editForm .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 1.2;
}

.editForm .block {
    width: 100%;
    margin-top: 20px;
}

.editForm .sellerInfo {
    margin-bottom: 20px;
}

.editForm .revertShipping {
    margin-top: 40px;
}

.editForm .tableItem {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.editForm .tableItemBody {
    min-height: 60px;
    word-break: break-word;
}

.editForm .tableTitle {
    font-size: 16px;
    font-weight: 500;
}

.editForm .productImg {
    max-height: 100px;
}